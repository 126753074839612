import React from "react";
import {
  SEO,
  MarkdownContent,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class CareersPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row className={""} style={{ padding: 0 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "ec7e4fb4-d49e-4d4d-81c1-c799816f38d9",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={0} />
          <Grid.Row className={""} style={{ padding: 0 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  className={""}
                  style={{}}
                  subheader={"Hiring Form"}
                  showLabels={true}
                  emailSubject={"Hiring Form"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: true,
                      inputType: "text",
                      placeholder: "Full name",
                      displayLabel: "Name",
                    },
                    {
                      type: "input",
                      label: "email",
                      required: true,
                      inputType: "text",
                      placeholder: "Email address",
                      displayLabel: "Email",
                    },
                    {
                      type: "input",
                      label: "phone",
                      required: true,
                      inputType: "text",
                      placeholder: "Phone number",
                      displayLabel: "Phone",
                    },
                    {
                      type: "input",
                      label: "address",
                      required: true,
                      inputType: "text",
                      placeholder: "Full address",
                      displayLabel: "Address",
                    },
                    {
                      type: "textarea",
                      label: "work_experience",
                      required: true,
                      inputType: "textarea",
                      placeholder:
                        "List your work experience: years, location and description",
                      displayLabel: "Work Experience",
                    },
                    {
                      type: "input",
                      label: "how_soon_could_you_start",
                      required: true,
                      inputType: "text",
                      placeholder: "Potential start date",
                      displayLabel: "How Soon Could You Start?",
                    },
                    {
                      type: "textarea",
                      label: "hours_available",
                      required: true,
                      inputType: "textarea",
                      placeholder: "Tell us the hours you are available",
                      displayLabel: "Hours Available",
                    },
                    {
                      type: "multiSelect",
                      label: "days_available",
                      options: [
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                        { label: "Sunday", value: "Sunday" },
                      ],
                      required: false,
                      inputType: "multiSelect",
                      placeholder: "Tell us the days you are available",
                      displayLabel: "Days Available",
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 23851 }) {
      title
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
